<template>
  <div class="table-wrapper">
    <v-card class="elevation-0 my-2 search-container">
      <v-col cols="12" v-if="['DEPOSIT', 'WITHDRAWAL'].includes(selectedTab)">
        <v-row class="cols" no-gutters>
          <v-btn class="history-tab" :color="selectedTab == 'DEPOSIT' ? 'primary' : 'outlined'" depressed @click="onChangeTab('DEPOSIT', true)">
            <span>{{ $t(`label.deposit`) }}</span>
          </v-btn>
          <v-btn class="history-tab" :color="selectedTab == 'WITHDRAWAL' ? 'primary' : 'outlined'" depressed @click="onChangeTab('WITHDRAWAL', true)">
            <span>{{ $t(`label.withdrawal`) }}</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="['REBATE', 'BONUS'].includes(selectedTab)">
        <v-row class="cols" no-gutters>
          <v-btn class="history-tab" :color="selectedTab == 'REBATE' ? 'primary' : 'outlined'" depressed @click="onChangeTab('REBATE', true)">
            <span>{{ $t(`button.rebate`) }}</span>
          </v-btn>
          <v-btn class="history-tab" :color="selectedTab == 'BONUS' ? 'primary' : 'outlined'" depressed @click="onChangeTab('BONUS', true)">
            <span>{{ $t(`label.bonus`) }}</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="['TRANSFER'].includes(selectedTab)">
        <v-row class="cols" no-gutters>
          <v-btn class="history-tab" :color="selectedTab == 'TRANSFER' ? 'primary' : 'outlined'" depressed @click="onChangeTab('TRANSFER', true)">
            <span>{{ $t(`label.transfer`) }}</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="['REDEMPTION'].includes(selectedTab)">
        <v-row class="cols" no-gutters>
          <v-btn class="history-tab" :color="selectedTab == 'REDEMPTION' ? 'primary' : 'outlined'" depressed @click="onChangeTab('REDEMPTION', true)">
            <span>{{ $t(`label.redemption`) }}</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-row no-gutters class="pa-2" v-if="!['REDEMPTION'].includes(selectedTab)">
        <v-col cols="12" sm="4" md="3">
          <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                clearable
                @click:clear="clearFilter()"
                outlined
                class="date-search elevation-0"
                hide-details="true"
                v-on="on"
                append-icon="mdi-magnify"
                hint="search"
                :dense="true"
              ></v-text-field>
            </template>
            <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-col cols="1" v-if="selectedTab == 'REBATE'" class="pl-2 pb-2 no-gutters">
        <v-btn v-if="totalRebate.unclaimed > 0" height="auto" @click="claimRebate()" depressed color="outlined" class="primary-button text-capitalize pa-2 font-weight-bold">
          {{ $t(`label.claim`) }}
        </v-btn>
      </v-col>
    </v-card>

    <!-- <v-row no-gutters justify="center">
      <v-col class="text-right" cols="12">
        <v-btn class="history-tab-mobile" :color="selectedTab == 'DEPOSIT' ? 'primary' : ''" depressed @click="onChangeTab('DEPOSIT')">
          <deposit-history-active class="mobile-icon" v-if="selectedTab == 'DEPOSIT'"></deposit-history-active>
          <deposit-history class="mobile-icon" v-else></deposit-history>
          <span v-if="selectedTab == 'DEPOSIT'">{{ $t(`label.deposit`) }}</span>
        </v-btn>
        <v-btn class="history-tab-mobile" :color="selectedTab == 'WITHDRAWAL' ? 'primary' : ''" depressed @click="onChangeTab('WITHDRAWAL')">
          <withdrawal-history class="mobile-icon" :class="selectedTab == 'WITHDRAWAL' ? 'mobile-icon-active' : ''"></withdrawal-history>
          <span v-if="selectedTab == 'WITHDRAWAL'">{{ $t(`label.withdrawal`) }}</span>
        </v-btn>
        <v-btn class="history-tab-mobile" :color="selectedTab == 'TRANSFER' ? 'primary' : ''" depressed @click="onChangeTab('TRANSFER')">
          <transfer-history class="mobile-icon" :class="selectedTab == 'TRANSFER' ? 'mobile-icon-active' : ''"></transfer-history>
          <span v-if="selectedTab == 'TRANSFER'">{{ $t(`label.transfer`) }}</span>
        </v-btn>
        <v-btn class="history-tab-mobile" :color="selectedTab == 'REBATE' ? 'primary' : ''" depressed @click="onChangeTab('REBATE')">
          <rebate-history class="mobile-icon" :class="selectedTab == 'REBATE' ? 'mobile-icon-active' : ''"></rebate-history>
          <span v-if="selectedTab == 'REBATE'">{{ $t(`button.rebate`) }}</span>
        </v-btn>
        <v-btn class="history-tab-mobile" :color="selectedTab == 'BONUS' ? 'primary' : ''" depressed @click="onChangeTab('BONUS')">
          <rebate-history class="mobile-icon" :class="selectedTab == 'BONUS' ? 'mobile-icon-active' : ''"></rebate-history>
          <span v-if="selectedTab == 'BONUS'">{{ $t(`label.bonus`) }}</span>
        </v-btn>
      </v-col>
    </v-row> -->
    <!-- <v-row v-else-if="$vuetify.breakpoint.lgAndUp" no-gutters justify="end" class="px-10">
        <v-col class="text-right" cols="12">
        <v-btn class="history-tab" :color="selectedTab == 'DEPOSIT' ? 'primary' : ''" depressed @click="onChangeTab('DEPOSIT')">
          <deposit-history-active class="mobile-icon" v-if="selectedTab == 'DEPOSIT'"></deposit-history-active>
          <deposit-history class="mobile-icon" v-else></deposit-history>
          {{ $t(`label.deposit`) }}
        </v-btn>
        <v-btn class="history-tab" :color="selectedTab == 'WITHDRAWAL' ? 'primary' : ''" depressed @click="onChangeTab('WITHDRAWAL')">
          <withdrawal-history class="mobile-icon" :class="selectedTab == 'WITHDRAWAL' ? 'mobile-icon-active' : ''"></withdrawal-history>
          {{ $t(`label.withdrawal`) }}
        </v-btn>
        <v-btn class="history-tab" :color="selectedTab == 'TRANSFER' ? 'primary' : ''" depressed @click="onChangeTab('TRANSFER')">
          <transfer-history class="mobile-icon" :class="selectedTab == 'TRANSFER' ? 'mobile-icon-active' : ''"></transfer-history>
          {{ $t(`label.transfer`) }}
        </v-btn>
        <v-btn class="history-tab" :color="selectedTab == 'REBATE' ? 'primary' : ''" depressed @click="onChangeTab('REBATE')">
          <rebate-history class="mobile-icon" :class="selectedTab == 'REBATE' ? 'mobile-icon-active' : ''"></rebate-history>
          {{ $t(`button.rebate`) }}
        </v-btn>
        <v-btn class="history-tab" :color="selectedTab == 'BONUS' ? 'primary' : ''" depressed @click="onChangeTab('BONUS')">
          <rebate-history class="mobile-icon" :class="selectedTab == 'BONUS' ? 'mobile-icon-active' : ''"></rebate-history>
          {{ $t(`label.bonus`) }}
        </v-btn>
      </v-col>
    </v-row> -->
    <div>
      <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters>
        <v-col cols="12" v-if="selectedTab == 'TRANSFER'">
          <v-data-table :headers="transferHeaders" :items="transactionList" class="elevation-0 history-table" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th class="history-table-header text-start" v-for="head in props.headers" :key="head.text">
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">mdi-chevron-down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">mdi-chevron-up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.created_at | longDate }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.from_wallet }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.to_wallet }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.amount | currency(currentCurrency) }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'FAILED'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'SUCCESS'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'PENDING'">mdi-clock</v-icon>
                  <span v-if="item.status == 'FAILED'">{{ $t(`transactionStatus.fail`) }}</span>
                  <span v-if="item.status == 'SUCCESS'">{{ $t(`transactionStatus.success`) }}</span>
                  <span v-if="item.status == 'PENDING'">{{ $t(`transactionStatus.pending`) }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="selectedTab == 'REBATE'">
          <v-row no-gutters class="pa-3">
            <v-col cols="12" class="pr-2">
              <label class="referral-table-header subtitle-1">{{ $t(`label.totalRebateUnclaimed`) }}{{ totalRebate.unclaimed | currency(currentCurrency) }}</label>
            </v-col>
            <v-col cols="12" class="pr-2">
              <label class="referral-table-header subtitle-1">{{ $t(`label.totalRebateAmt`) }}</label>
              <label>{{ totalRebate.total | currency(currentCurrency) }}</label>
            </v-col>
          </v-row>
          <v-data-table :headers="rebateHeaders" :items="transactionList" class="elevation-0 history-table" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th class="history-table-header text-start" v-for="head in props.headers" :key="head.text">
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">mdi-chevron-down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">mdi-chevron-up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>

            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.bet_time_end | longDate }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.valid_bet_amount | currency(currentCurrency) }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.vip_percentage }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.rebate_amount | currency(currentCurrency) }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.provider_code }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.game_type }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.fk_rebate_status_id == 1 ? $t(`label.claimed`) : $t(`label.unclaimed`) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="selectedTab == 'DEPOSIT'">
          <v-data-table :headers="depositHeaders" :items="transactionList" class="elevation-0 history-table" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th class="history-table-header text-start" v-for="head in props.headers" :key="head.text">
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">mdi-chevron-down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">mdi-chevron-up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.date | longDate }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.method }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.amount | currency(currentCurrency) }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.bonus_name ? item.bonus_name : '-' }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'Approved and completed'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">mdi-clock</v-icon>
                  <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                  <span v-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                  <span v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">{{ $t(`transactionStatus.pending`) }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="selectedTab == 'WITHDRAWAL'">
          <v-data-table :headers="withdrawalHeaders" :items="transactionList" class="elevation-0 history-table" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th class="history-table-header text-start" v-for="head in props.headers" :key="head.text">
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">mdi-chevron-down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">mdi-chevron-up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.date | longDate }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.amount | currency(currentCurrency) }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.method }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-else-if="item.status == 'Approved and completed'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-else>mdi-clock</v-icon>
                  <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                  <span v-else-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                  <span v-else>{{ $t(`transactionStatus.pending`) }}</span>
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.remarks | emptyDataFormat }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="selectedTab == 'BONUS'">
          <v-data-table :headers="bonusHeaders" :items="transactionList" class="elevation-0 history-table" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th class="history-table-header text-start" v-for="head in props.headers" :key="head.text">
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">mdi-chevron-down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">mdi-chevron-up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.bonus.created_at | longDate }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.bonus.name }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.ref_id }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.bonus_given_amount | currency(currentCurrency) }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'Canceled'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'Success'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending'">mdi-clock</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending CS'">mdi-clock</v-icon>
                  <span v-if="item.status == 'Canceled'">{{ $t(`bonusStatus.canceled`) }}</span>
                  <span v-if="item.status == 'Success'">{{ $t(`bonusStatus.success`) }}</span>
                  <span v-if="item.status == 'Pending'">{{ $t(`bonusStatus.pending`) }}</span>
                  <span v-if="item.status == 'Pending CS'">{{ $t(`bonusStatus.pendingCustomerService`) }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="selectedTab == 'REDEMPTION'">
          <v-data-table :headers="redeemHeaders" :items="transactionList" class="elevation-0 history-table" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th class="history-table-header text-start" v-for="head in props.headers" :key="head.text">
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">mdi-chevron-down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">mdi-chevron-up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.created_at | longDate }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.reward_item_name }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.reward_category_name }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.reward_variation }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.reciever_name }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.reciever_mobile }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.reciever_address_1 }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.reciever_address_2 }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.city }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.postcode }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.tracking }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.trx_id }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  {{ item.updated_at }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0, 0, 0, 0.12); padding: 10px 16px">
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'CANCELED'">mdi-close-circle-outline</v-icon>
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'REJECTED'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'SUCCESS'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'APPROVED'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'PENDING'">mdi-clock</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending CS'">mdi-clock</v-icon>
                  <span v-if="item.status == 'CANCELED'">{{ $t(`bonusStatus.canceled`) }}</span>
                  <span v-if="item.status == 'REJECTED'">{{ $t(`bonusStatus.rejected`) }}</span>
                  <span v-if="item.status == 'SUCCESS'">{{ $t(`bonusStatus.success`) }}</span>
                  <span v-if="item.status == 'APPROVED'">{{ $t(`bonusStatus.approved`) }}</span>
                  <span v-if="item.status == 'PENDING'">{{ $t(`bonusStatus.pending`) }}</span>
                  <span v-if="item.status == 'Pending CS'">{{ $t(`bonusStatus.pendingCustomerService`) }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row no-gutters v-else>
        <v-col cols="12" v-if="this.selectedTab == 'DEPOSIT'">
          <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
            <v-col cols="6" class="mr-0">
              <v-row no-gutters class="history-table-header pl-6">
                <v-col cols="12" v-for="h in depositHeaders" :key="h.text">
                  {{ $t(`label.${h.text}`) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  {{ item.date | longDate }}
                </v-col>
                <v-col cols="12">
                  {{ item.method }}
                </v-col>
                <v-col cols="12">
                  {{ item.amount | currency(currentCurrency) }}
                </v-col>
                <v-col cols="12">
                  <label>{{ item.bonus_name ? item.bonus_name : '-' }}</label>
                </v-col>
                <v-col cols="12">
                  <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                  <span v-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                  <span v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">{{ $t(`transactionStatus.pending`) }}</span>
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'Approved and completed'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">mdi-clock</v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider class="mx-2"></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="this.selectedTab == 'WITHDRAWAL'">
          <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
            <v-col cols="6" class="mr-0">
              <v-row no-gutters class="history-table-header pl-6">
                <v-col cols="12" v-for="h in withdrawalHeaders" :key="h.text">
                  {{ $t(`label.${h.text}`) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  {{ item.date | longDate }}
                </v-col>
                <v-col cols="12">
                  {{ item.amount | currency(currentCurrency) }}
                </v-col>
                <v-col cols="12">
                  {{ item.method }}
                </v-col>
                <v-col cols="12">
                  <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                  <span v-else-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                  <span v-else>{{ $t(`transactionStatus.pending`) }}</span>
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-else-if="item.status == 'Approved and completed'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-else>mdi-clock</v-icon>
                </v-col>
                <v-col cols="12" class="remarks-col">
                  {{ item.remarks | emptyDataFormat }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider class="mx-2"></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="this.selectedTab == 'TRANSFER'">
          <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
            <v-col cols="6" class="mr-0">
              <v-row no-gutters class="history-table-header pl-6">
                <v-col cols="12" v-for="h in transferHeaders" :key="h.text">
                  {{ $t(`label.${h.text}`) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  {{ item.created_at | longDate }}
                </v-col>
                <v-col cols="12">
                  {{ item.from_wallet }}
                </v-col>
                <v-col cols="12">
                  {{ item.to_wallet }}
                </v-col>
                <v-col cols="12">
                  {{ item.amount | currency(currentCurrency) }}
                </v-col>
                <v-col cols="12">
                  <span v-if="item.status == 'FAILED'">{{ $t(`transactionStatus.fail`) }}</span>
                  <span v-if="item.status == 'SUCCESS'">{{ $t(`transactionStatus.success`) }}</span>
                  <span v-if="item.status == 'PENDING'">{{ $t(`transactionStatus.pending`) }}</span>
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'FAILED'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'SUCCESS'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'PENDING'">mdi-clock</v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider class="mx-2"></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="this.selectedTab == 'REBATE'">
          <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
            <v-col cols="6" class="mr-0">
              <v-row no-gutters class="history-table-header pl-6">
                <v-col cols="12" v-for="h in rebateHeaders" :key="h.text">
                  {{ $t(`label.${h.text}`) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  {{ item.bet_time_end | longDate }}
                </v-col>
                <v-col cols="12">
                  {{ item.valid_bet_amount | currency(currentCurrency) }}
                </v-col>
                <v-col cols="12">
                  {{ item.vip_percentage }}
                </v-col>
                <v-col cols="12">
                  {{ item.rebate_amount | currency(currentCurrency) }}
                </v-col>
                <v-col cols="12">
                  {{ item.provider_code }}
                </v-col>
                <v-col cols="12">
                  {{ item.game_type }}
                </v-col>
                <v-col cols="12">
                  {{ item.fk_rebate_status_id == 1 ? $t(`label.claimed`) : $t(`label.unclaimed`) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider class="mx-2"></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="this.selectedTab == 'BONUS'">
          <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
            <v-col cols="6" class="mr-0">
              <v-row no-gutters class="history-table-header pl-6">
                <v-col cols="12" v-for="h in bonusHeaders" :key="h.text">
                  {{ $t(`label.${h.text}`) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  {{ item.bonus.created_at | longDate }}
                </v-col>
                <v-col cols="12">
                  {{ item.bonus.name }}
                </v-col>
                <v-col cols="12">
                  {{ item.ref_id }}
                </v-col>
                <v-col cols="12">
                  {{ item.bonus_given_amount | currency(currentCurrency) }}
                </v-col>
                <v-col cols="12">
                  <span v-if="item.status == 'Canceled'">{{ $t(`bonusStatus.canceled`) }}</span>
                  <span v-if="item.status == 'Success'">{{ $t(`bonusStatus.success`) }}</span>
                  <span v-if="item.status == 'Pending'">{{ $t(`bonusStatus.pending`) }}</span>
                  <span v-if="item.status == 'Pending CS'">{{ $t(`bonusStatus.pendingCustomerService`) }}</span>
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'Canceled'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'Success'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending'">mdi-clock</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending CS'">mdi-clock</v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider class="mx-2"></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="this.selectedTab == 'REDEMPTION'">
          <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
            <v-col cols="6" class="mr-0">
              <v-row no-gutters class="history-table-header pl-6">
                <v-col cols="12" v-for="h in redeemHeaders" :key="h.text">{{ $t(`label.${h.text}`) }} :</v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  {{ item.created_at | longDate }}
                </v-col>
                <v-col cols="12">
                  {{ item.reward_item_name }}
                </v-col>
                <v-col cols="12">
                  {{ item.reward_category_name }}
                </v-col>
                <v-col cols="12">
                  {{ item.reward_variation }}
                </v-col>
                <v-col cols="12">
                  {{ item.reciever_name }}
                </v-col>
                <v-col cols="12">
                  {{ item.reciever_mobile }}
                </v-col>
                <v-col cols="12">
                  {{ item.reciever_address_1 }}
                </v-col>
                <v-col cols="12">
                  {{ item.reciever_address_2 }}
                </v-col>
                <v-col cols="12">
                  {{ item.city }}
                </v-col>
                <v-col cols="12">
                  {{ item.postcode }}
                </v-col>
                <v-col cols="12">
                  {{ item.tracking }}
                </v-col>
                <v-col cols="12">
                  {{ item.trx_id }}
                </v-col>
                <v-col cols="12">
                  {{ item.updated_at }}
                </v-col>
                <v-col cols="12">
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'CANCELED'">mdi-close-circle-outline</v-icon>
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'REJECTED'">mdi-close-circle-outline</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'SUCCESS'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'APPROVED'">mdi-checkbox-marked-circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'PENDING'">mdi-clock</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending CS'">mdi-clock</v-icon>
                  <span v-if="item.status == 'CANCELED'">{{ $t(`bonusStatus.canceled`) }}</span>
                  <span v-if="item.status == 'REJECTED'">{{ $t(`bonusStatus.rejected`) }}</span>
                  <span v-if="item.status == 'SUCCESS'">{{ $t(`bonusStatus.success`) }}</span>
                  <span v-if="item.status == 'APPROVED'">{{ $t(`bonusStatus.approved`) }}</span>
                  <span v-if="item.status == 'PENDING'">{{ $t(`bonusStatus.pending`) }}</span>
                  <span v-if="item.status == 'Pending CS'">{{ $t(`bonusStatus.pendingCustomerService`) }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider class="mx-2"></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="transactionList.length == 0" class="text-center pt-0 pb-8">
          <label class="history-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</label>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-1" v-if="pagination.last_page > 1">
        <v-col cols="12" class="text-center">
          <v-pagination
            class="trxn_history_pagination"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="changePage"
            :next-icon="'mdi-chevron-right'"
            :prev-icon="'mdi-chevron-left'"
            :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import depositHistoryActive from '@/assets/svg/deposit-history-active.svg'
import depositHistory from '@/assets/svg/deposit-history.svg'
import withdrawalHistory from '@/assets/svg/withdrawal-history.svg'
import transferHistory from '@/assets/svg/transfer-history.svg'
import rebateHistory from '@/assets/svg/rebate-history.svg'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { REBATE_HISTORY, CLAIM_REBATE, BONUS_HISTORY } from '@/store/bonus.module'
import { TRANSACTION_HISTORY } from '@/store/transfer.module'
import { PAYMENT_HISTORY } from '@/store/payment.module'
import { MEMBER_REDEEM_HISTORY } from '@/store/reward.module'

export default {
  name: 'transactionHistory',
  components: {
    depositHistory,
    depositHistoryActive,
    withdrawalHistory,
    transferHistory,
    rebateHistory
  },
  data: function() {
    return {
      dateMenu: false,
      dates: [SHARED.DEFAULT_DATE, SHARED.DEFAULT_DATE],
      language: uiHelper.getLanguage(),
      currentCurrency: uiHelper.getCurrency(),
      validator: formValidator,
      shared: SHARED,
      selectedTab: this.$route.params.tab ? this.$route.params.tab : 'BONUS',
      msgDialog: {
        show: false
      },
      inboxList: [],
      announcementList: [],
      selectedList: [],
      pagination: {
        currentPage: 1,
        totalPage: 1,
        paginationPageLimit: 10
      },
      searchCriteria: {
        pageNumber: 1,
        pageSize: 20,
        mobilePageSize: 10
      },
      depositHeaders: [
        {
          text: 'date',
          align: 'start',
          sortable: true,
          value: 'date',
          sortBy: 'desc'
        },
        {
          text: 'depositMethod',
          value: 'membercode',
          sortable: false
        },
        {
          text: 'depositAmt',
          value: 'membercode',
          sortable: false
        },
        {
          text: 'bonus',
          value: 'membercode',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          sortable: false
        }
      ],
      withdrawalHeaders: [
        {
          text: 'date',
          align: 'start',
          sortable: true,
          value: 'date',
          sortBy: 'desc'
        },
        {
          text: 'withdrawalAmt',
          value: 'membercode',
          sortable: false
        },
        {
          text: 'paymentMethod',
          value: 'commission',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          sortable: false
        },
        {
          text: 'remarks',
          value: 'remarks',
          sortable: false
        }
      ],
      transferHeaders: [
        {
          text: 'transactionDate',
          align: 'start',
          sortable: true,
          value: 'created_at',
          sortBy: 'desc'
        },
        {
          text: 'fromWallet',
          value: 'membercode',
          sortable: false
        },
        {
          text: 'toWallet',
          value: 'commission',
          sortable: false
        },
        {
          text: 'amount',
          value: 'status',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          sortable: false
        }
      ],
      rebateHeaders: [
        {
          text: 'date',
          align: 'start',
          sortable: true,
          value: 'bet_time_end',
          sortBy: 'desc'
        },
        {
          text: 'valid',
          value: 'valid_bet_amount',
          sortable: false
        },
        {
          text: 'rebate',
          value: 'vip_percentage',
          sortable: false
        },
        {
          text: 'rebateAmount',
          value: 'rebate_amount',
          sortable: false
        },
        {
          text: 'provider',
          value: 'activity',
          sortable: false
        },
        {
          text: 'category',
          value: 'provider_code',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          sortable: false
        }
      ],
      redeemHeaders: [
        {
          text: 'date',
          align: 'start',
          sortable: true,
          value: 'created_at',
          sortBy: 'desc'
        },
        {
          text: 'rewardItemName',
          value: 'reward_item_name',
          sortable: false
        },
        {
          text: 'rewardCategoryName',
          value: 'reward_category_name',
          sortable: false
        },
        {
          text: 'rewardVariation',
          value: 'reward_variation',
          sortable: false
        },
        {
          text: 'receiverName',
          value: 'reciever_name',
          sortable: false
        },
        {
          text: 'receiverMobile',
          value: 'reciever_mobile',
          sortable: false
        },
        {
          text: 'receiverAddress1',
          value: 'reciever_address_1',
          sortable: false
        },
        {
          text: 'receiverAddress2',
          value: 'reciever_address_2',
          sortable: false
        },
        {
          text: 'city',
          value: 'city',
          sortable: false
        },
        {
          text: 'postcode',
          value: 'postcode',
          sortable: false
        },
        {
          text: 'tracking',
          value: 'tracking',
          sortable: false
        },
        {
          text: 'transactionId',
          value: 'trx_id',
          sortable: false
        },
        {
          text: 'updatedAt',
          value: 'updated_at',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          sortable: false
        }
      ],
      bonusHeaders: [
        {
          text: 'date',
          align: 'start',
          sortable: true,
          value: 'bonusApplyDate',
          sortBy: 'desc'
        },
        {
          text: 'bonusType',
          value: 'name',
          sortable: false
        },
        {
          text: 'bonusID',
          value: 'refId',
          sortable: false
        },
        {
          text: 'bonusHistoryAmount',
          value: 'bonusGivenAmount',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          sortable: false
        }
      ],
      transactionList: [],
      selectedPage: 1
    }
  },
  created() {
    if (window.location.pathname.indexOf(ROUTE_NAME.TRANSFER_HISTORY) > -1) {
      this.selectedTab = 'TRANSFER'
      this.getTransferHistory()
    } else if (window.location.pathname.indexOf(ROUTE_NAME.BONUS_HISTORY) > -1) {
      this.selectedTab = 'REBATE'
      this.getRebateHistory()
    } else if (window.location.pathname.indexOf(ROUTE_NAME.TRANSACTION_HISTORY) > -1) {
      this.selectedTab = 'DEPOSIT'
      this.getPaymentHistory('DEPOSIT')
    } else if (window.location.pathname.indexOf(ROUTE_NAME.REDEMPTION_HISTORY) > -1) {
      this.getRedeemHistory()
    }
    this.metaTag()
  },
  computed: {
    redeemHistory() {
      return this.$store.state.reward.redeemHistory.data
    },
    dateRangeText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        let endDate = this.dates[1] ? this.dates[1] : ''
        if (startDate || endDate) {
          return startDate + ' - ' + endDate
        }
      },
      set(dateRange) {
        this.dates = []
      }
    },
    paymentHistory() {
      return this.$store.state.payment.paymentHistory.data
    },
    paymentHistoryPagination() {
      return this.$store.state.payment.paymentHistory.pagination
    },
    transactionHistory() {
      return this.$store.state.transfer.transactionHistory.data
    },
    transactionHistoryPagination() {
      return this.$store.state.transfer.transactionHistory.pagination
    },
    rebateHistory() {
      return this.$store.state.bonus.rebateHistory.data
    },
    rebateHistoryPagination() {
      return this.$store.state.bonus.rebateHistory.pagination
    },
    bonusHistory() {
      return this.$store.state.bonus.bonusHistory.data
    },
    bonusHistoryPagination() {
      return this.$store.state.bonus.bonusHistory.pagination
    },
    totalRebateAmt() {
      if (this.selectedTab == 'REBATE') {
        let amt = 0
        this.$store.state.bonus.rebateHistory.data.forEach(b => {
          amt += b.rebate_amount
        })
        return amt
      }
      return 0
    },
    totalRebatePercentage() {
      if (this.selectedTab == 'REBATE') {
        let amt = 0
        this.$store.state.bonus.rebateHistory.data.forEach(b => {
          amt += b.vip_percentage
        })
        return amt
      }
      return 0
    },
    totalRebate() {
      return this.$store.state.bonus.rebateHistory.totalRebate
    },
    claimRebateResult() {
      return this.$store.state.bonus.claimRebateResult.complete
    }
  },
  watch: {
    redeemHistory() {
      this.getTransactionList()
    },
    paymentHistory() {
      this.getTransactionList()
    },
    transactionHistory() {
      this.getTransactionList()
    },
    rebateHistory() {
      this.getTransactionList()
    },
    bonusHistory() {
      this.getTransactionList()
    },
    claimRebateResult() {
      if (this.$store.state.bonus.claimRebateResult.complete) {
        this.claimRebateResponse(this.$store.state.bonus.claimRebateResult)
      }
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        let self = this
        setTimeout(function() {
          self.dateMenu = false
        }, 500)
      }
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
      if (this.selectedTab == 'DEPOSIT') {
        this.getPaymentHistory(this.selectedTab)
      } else if (this.selectedTab == 'WITHDRAWAL') {
        this.getPaymentHistory(this.selectedTab)
      } else if (this.selectedTab == 'TRANSFER') {
        this.getTransferHistory()
      } else if (this.selectedTab == 'REBATE') {
        this.getRebateHistory()
      } else if (this.selectedTab == 'BONUS') {
        this.getBonusHistory()
      } else if (this.selectedTab == 'REDEMPTION') {
        this.getRedeemHistory()
      }
    },
    onChangeTab(type, filterDate) {
      this.selectedTab = type
      if (!filterDate) {
        this.dates = ['', '']
      }
      this.selectedPage = 1
      if (this.selectedTab == 'DEPOSIT') {
        this.getPaymentHistory(type)
      } else if (this.selectedTab == 'WITHDRAWAL') {
        this.getPaymentHistory(type)
      } else if (this.selectedTab == 'TRANSFER') {
        this.getTransferHistory()
      } else if (this.selectedTab == 'REBATE') {
        this.getRebateHistory()
      } else if (this.selectedTab == 'BONUS') {
        this.getBonusHistory()
      } else if (this.selectedTab == 'REDEMPTION') {
        this.getRedeemHistory()
      }
    },
    getBonusHistory(type) {
      let bonusHistoryObj = {
        type: type,
        overallStatus: 'history',
        fromDate: isEmpty(this.dates[0]) ? this.dates[0] : this.dates[0] + ' 00:00:00',
        toDate: isEmpty(this.dates[1]) ? this.dates[1] : this.dates[1] + ' 23:59:59',
        page: this.selectedPage,
        itemsPerPage: 10
      }
      this.$store.dispatch(`${BONUS_HISTORY}`, {
        bonusHistoryObj
      })
    },
    getPaymentHistory(type) {
      let obj = {
        type: type,
        startTime: isEmpty(this.dates[0]) ? this.dates[0] : this.dates[0] + ' 00:00:00',
        endTime: isEmpty(this.dates[1]) ? this.dates[1] : this.dates[1] + ' 23:59:59',
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${PAYMENT_HISTORY}`, {
        obj
      })
    },
    getRedeemHistory() {
      this.$store.dispatch(`${MEMBER_REDEEM_HISTORY}`, {})
    },
    getTransferHistory() {
      let obj = {
        startTime: isEmpty(this.dates[0]) ? this.dates[0] : this.dates[0] + ' 00:00:00',
        endTime: isEmpty(this.dates[1]) ? this.dates[1] : this.dates[1] + ' 23:59:59',
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${TRANSACTION_HISTORY}`, {
        obj
      })
    },
    getRebateHistory(type) {
      let obj = {
        type: type,
        startTime: isEmpty(this.dates[0]) ? this.dates[0] : this.dates[0] + ' 00:00:00',
        endTime: isEmpty(this.dates[1]) ? this.dates[1] : this.dates[1] + ' 23:59:59',
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REBATE_HISTORY}`, {
        obj
      })
    },
    getTransactionList() {
      if (this.selectedTab == 'DEPOSIT') {
        this.transactionList = cloneDeep(this.paymentHistory)
        this.pagination = this.paymentHistoryPagination
      } else if (this.selectedTab == 'WITHDRAWAL') {
        this.transactionList = cloneDeep(this.paymentHistory)
        this.pagination = this.paymentHistoryPagination
      } else if (this.selectedTab == 'TRANSFER') {
        this.transactionList = cloneDeep(this.transactionHistory)
        this.pagination = this.transactionHistoryPagination
      } else if (this.selectedTab == 'REBATE') {
        this.transactionList = cloneDeep(this.rebateHistory)
        this.pagination = this.rebateHistoryPagination
      } else if (this.selectedTab == 'BONUS') {
        this.transactionList = cloneDeep(this.bonusHistory)
        this.pagination = this.bonusHistoryPagination
      } else if (this.selectedTab == 'REDEMPTION') {
        this.transactionList = cloneDeep(this.redeemHistory)
        // this.pagination = this.redeemHistoryPagination
      }
    },
    clearFilter() {
      this.dates = ['', '']
      this.onChangeTab(this.selectedTab, false)
    },
    filterListByDate() {
      this.onChangeTab(this.selectedTab, true)
    },
    claimRebate() {
      //this.$store.state.bonus.claimRebateResult.complete = true
      this.$store.dispatch(`${CLAIM_REBATE}`)
    },
    claimRebateResponse(result) {
      if (result.success && result.code == 0) {
        this.$parent.openAlert(true, locale.getMessage(`message.rebateSubmitSuccess`))
      } else {
        this.$parent.openAlert(false, locale.getMessage(`message.vipBonusFailed`))
      }
    },
    sortTableList(obj) {
      let headers = []
      if (this.selectedTab == 'DEPOSIT') {
        headers = this.depositHeaders
      } else if (this.selectedTab == 'WITHDRAWAL') {
        headers = this.withdrawalHeaders
      } else if (this.selectedTab == 'TRANSFER') {
        headers = this.transferHeaders
      } else if (this.selectedTab == 'REBATE') {
        headers = this.rebateHeaders
      } else if (this.selectedTab == 'REDEMPTION') {
        headers = this.redeemHeaders
      } else {
        headers = this.bonusHeaders
      }
      let sortBy = filter(headers, function(d) {
        return d.sortable
      })
      if (obj) {
        let selected = find(sortBy, function(d) {
          if (d.value == obj.value) {
            d.sortBy = d.sortBy == 'asc' ? 'desc' : 'asc'
            return true
          }
        })
      }
      this.transactionList = orderBy(this.transactionList, map(sortBy, 'value'), map(sortBy, 'sortBy'))
    }
  }
}
</script>

<style lang="scss">
.v-date-picker-table {
  height: auto;
}
.table-wrapper {
  max-width: 1240px;
  margin: auto;

  .search-container {
    background: var(--v-background-lighten1);

    .outlined {
      border: 1px solid var(--v-border-base);
    }

    .date-search {
      max-width: 290px;
    }
  }

  .history-table {
    white-space: nowrap;

    .history-table-header {
      background: var(--v-background-darken1);
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      padding: 10px 16px;

      &:not(:last-child) {
        border-style: none dashed none none;
        border-color: rgba(255, 255, 255, 0.9);
        border-width: thin;
      }
    }
  }

  .v-text-field--outlined fieldset {
    border-color: unset;
  }
}

.remarks-col {
  white-space: normal !important;
}

.trxn_history_pagination .v-pagination {
  li {
    border: none !important;
    button {
      border: none !important;
      font-size: 14px;
    }
  }
}
.mobile-history-table {
  .col-12 {
    min-height: 20px;
  }
}
.history-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  .date-search {
    width: fit-content;
    max-width: 290px;
    margin-right: 0px;
    float: right !important;
    .v-input__slot {
      min-height: 40px !important;
      max-width: 250px;
    }
    .v-input__append-inner {
      margin-top: 8px;
    }
  }
  .history-table-header {
    color: #acacac;
  }
}
.history-tab {
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  //background-color: #fff;
  text-transform: capitalize;
  .mobile-icon {
    margin-right: 5px;
    width: 15px;
    height: 19px;
  }
  .mobile-icon-active {
    fill: #3a3a3a !important;
    path {
      fill: #fff !important;
    }
  }
}
.history-tab-mobile {
  width: fit-content !important;
  margin-right: 5px;
  min-width: fit-content !important;
  padding: 2px;
  //background-color: #fff;
  text-transform: capitalize;
  font-size: 12px !important;
  .mobile-icon {
    margin-right: 3px;
    width: 15px;
    height: 19px;
  }
  .mobile-icon-active {
    fill: #3a3a3a !important;
    path {
      fill: #fff !important;
    }
  }
}
.date-search-mobile {
  .v-input__slot {
    min-height: 40px !important;
  }
  .v-input__append-inner {
    margin-top: 8px;
  }
}
@media (max-width: 959px) {
  .history-card {
    font-size: 12px;
    height: 80%;
    overflow-x: hidden;
    overflow-y: hidden;
    .date-search {
      width: fit-content;
      margin-right: 0px;
      float: right !important;
      .v-input__slot {
        min-height: 40px !important;
        max-width: 250px;
      }
      .v-input__append-inner {
        margin-top: 8px;
      }
    }
    .history-table-header {
      color: #acacac;
    }
  }
}
</style>
