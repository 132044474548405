<template>
  <v-card class="desktop_card">
    <v-card-title>
      <label class="--v-primary-base text-left text-capitalize mr-4">{{ $t(`label.rewards`) }}</label>
    </v-card-title>
    <v-card class="mt-4 profile-detail-card rewards-card" elevation="0">
      <v-row align="center" class="px-8" :class="$vuetify.breakpoint.lgAndUp ? 'pt-4' : ''">
        <v-col cols="5" sm=2 md=3 lg=2>
          <label> {{ $t(`label.coinsRewards`) }}</label>
        </v-col>
        <v-col cols="7" sm=3 md=3 lg=2>
          <v-card color="footer_color text-center" elevation="0" class="">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="auto" class="text-right">
                <img src="/static/svg/coin-event.svg" class="mt-1" height="24" width="24"/>
              </v-col>
              <v-col cols="auto" class="pl-2 text-left">
                {{ memberInfo.rewardBalance ? memberInfo.rewardBalance : 0 }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm=6 md=6 lg=4>
          <v-row no-gutters justify="space-between">
            <v-col cols="3" v-if="configuration.daily_check_in.enabled == true">
              <v-row no-gutters>
                <v-col class="text-center" cols=12>
                  <v-btn icon>
                    <img src="/static/svg/reward-checkin.svg" height="40" width="40" @click="openDailyRewardsDialog()"/>
                  </v-btn>
                </v-col>
                <v-col class="text-center mt-1" cols=12>
                  <label class="reward-icon-label">{{ $t(`label.checkin`) }}</label>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" v-if="configuration.spin_wheel.enabled == true">
              <v-row no-gutters>
                <v-col class="text-center" cols=12>
                  <v-btn icon>
                      <img src="/static/svg/icon-luckyspin.svg" height="40" width="40"  @click="navigateWOFPage()" />
                  </v-btn>
                </v-col>
                <v-col class="text-center mt-1" cols=12>
                  <label class="reward-icon-label">{{ $t(`label.luckyWheel`) }}</label>
                </v-col>
              </v-row>
            </v-col>
<!--            <v-col cols="3" v-if="configuration.coin_grab.enabled == true">-->
<!--              <v-row no-gutters>-->
<!--                <v-col class="text-center" cols=12>-->
<!--                  <v-btn icon>-->
<!--                    <img src="/static/svg/icon-grabcoin.svg" height="40" width="40"  @click="navigateToCoinPage()"/>-->
<!--                  </v-btn>-->
<!--                </v-col>-->
<!--                <v-col class="text-center mt-1" cols=12>-->
<!--                  <label class="reward-icon-label">{{ $t(`label.coinGrab`) }}</label>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-col>-->
            <v-col cols="3" v-if="configuration.card_mania.enabled == true">
              <v-row no-gutters>
                <v-col class="text-center" cols=12>
                  <v-btn icon>
                    <img src="/static/image/profile/icon-card-mania.svg" height="40" width="40" @click="navigateToCardManiaPage()"/>
                  </v-btn>
                </v-col>
                <v-col class="text-center mt-1" cols=12>
                  <label class="reward-icon-label">{{ $t(`label.cardMania`) }}</label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-btn class="mr-2" icon>
              <img src="/static/svg/reward-redeem.svg" />
          </v-btn> -->
        </v-col>
        <v-col cols=12 sm=12>
          <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y
                  transition="scale-transition" max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="dateRangeText" clearable
                            @click:clear="clearFilter()" outlined
                            class="rewards-date-search ml-3 elevation-0" hide-details="true" v-on="on"
                            append-icon="mdi-magnify"></v-text-field>
            </template>
            <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title
                           v-model="dates" @input="selectedDate()" range></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 v-if="$vuetify.breakpoint.mdAndUp">
          <v-data-table light
                        :headers="headers" :items="transactionList" class="elevation-0" :hide-default-footer="true"
                        hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th class="reward-table-header text-start"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"
                    v-for="head in props.headers" :key="head.text">
                              <span v-if="head.text !=='dateSearch'"> {{ $t(`label.${head.text}`) }}
                                  <span v-if="head.sortable">
                                      <v-icon v-if="head.sortBy == 'asc'"
                                              @click="sortTableList(head)">mdi-chevron-down</v-icon>
                                      <v-icon v-if="head.sortBy == 'desc'"
                                              @click="sortTableList(head)">mdi-chevron-up</v-icon>
                                  </span>
                              </span>
                </th>
                <!-- <th class="reward-table-header text-end"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y
                        transition="scale-transition" max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="dateRangeText" clearable
                                @click:clear="clearFilter()" outlined
                                class="date-search ml-3 elevation-0" hide-details="true" v-on="on"
                                append-icon="search"></v-text-field>
                        </template>
                        <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title
                            v-model="dates" @input="dateMenu = false" range></v-date-picker>
                    </v-menu>
                </th> -->
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.updated_at | longDate }}
                </td>
                <td class="text-start"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.trx_id }}
                </td>
                <td class="text-start"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.balance_before }}
                </td>
                <td class="text-start"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.balance_after }}
                </td>
                <td class="text-start"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.amount }}
                </td>
                <td class="text-start text-capitalize"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  <!-- <v-icon color="red" class="mb-1" small v-if="item.status == 'failed'">cancel</v-icon>
      <v-icon color="green" class="mb-1" small v-if="item.status == 'success'">check_circle</v-icon>
      <v-icon color="yellow" class="mb-1" small v-if="item.status == 'pending'">watch_later</v-icon> -->
                  {{ item.activity }}
                </td>
                <!-- <td class="text-start"
                    style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                </td> -->
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols=12 v-else>
          <v-row class="mx-1 mobile-reward-table" v-for="(item, index) in transactionList" :key="index">
            <v-col cols=6 class="mr-0">
              <v-row no-gutters class="reward-table-header pl-6">
                <v-col cols=12 v-for="h in headers" :key="h.text">
                  {{ $t(`label.${h.text}`) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=6>
              <v-row no-gutters>
                <v-col cols=12>
                  {{ item.updated_at | longDate }}
                </v-col>
                <v-col cols=12>
                  {{ item.trx_id }}
                </v-col>
                <v-col cols=12>
                  {{ item.balance_before }}
                </v-col>
                <v-col cols=12>
                  {{ item.balance_after }}
                </v-col>
                <v-col cols=12>
                  {{ item.amount }}
                </v-col>
                <v-col cols=12>
                  {{ item.activity }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=12>
              <v-divider class="mx-2"></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="transactionList.length == 0" class="text-center pt-0 pb-8">
          <label class="reward-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</label>
        </v-col>
      </v-row>
      <v-row class="mb-6" v-if="pagination.last_page > 1">
        <v-col cols=12 class="text-center">
          <v-pagination class="reward_log_pagination" v-model="pagination.current_page" :length="pagination.last_page"
                        @input="changePage"
                        :next-icon="'mdi-chevron-right'"
                        :prev-icon="'mdi-chevron-left'"
                        :total-visible="pagination.per_page"></v-pagination>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog content-class="daily-rewards-dialog" persistent :value="dailyRewardsDialogShow" style="margin:10px" :max-width="440">
        <v-card class="daily-rewards-card">
            <v-row justify="space-between" no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'dialog-header pt-5 px-5' :'dialog-header pt-8 px-5'">
                <v-col cols=11>
                    <label class="dialog-title pt-3 ma-0 text-capitalize d-block full-width">{{ $t(`label.dailyCheckIn`) }}</label>
                </v-col>
                <v-col cols=1>
                    <v-icon :class="'dialog-close-icon'" @click="closeDailyRewardsDialog">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'px-5 py-3' :'px-5 py-6'">
                <v-col cols="12">
                    <v-divider class="dialog-divider"></v-divider>
                </v-col>
            </v-row>
            <v-row no-gutters class="d-block dialog-row" v-if="dailyRewardsDialogShow">
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="mb-8" :class="$vuetify.breakpoint.mdAndDown ? 'px-5' :'px-5'">
                     <v-card color="footer_color text-center" elevation="0" class="">
                        <v-row no-gutters align="center" justify="center">
                          <v-col cols="auto" class="text-right">
                            <img src="/static/svg/coin-event.svg" class="mt-1" height="24" width="24"/>
                          </v-col>
                          <v-col cols="auto" class="pl-2 text-left" style="font-size:14px">
                              {{memberInfo.rewardBalance ? memberInfo.rewardBalance : 0}}
                          </v-col>
                        </v-row>
                      </v-card>
                  </v-col>
                  <v-col cols="12" class="mb-8" :class="$vuetify.breakpoint.mdAndDown ? 'px-5' :'px-5'">
                    <v-row no-gutters justify="end">
                      <v-col v-for="(r, index) in dailyRewards" :key="index">
                        <v-row no-gutters class="daily-coins-progress">
                          <v-col>
                            <v-row no-gutters>
                              <v-col cols=12 class="text-center">
                                {{r.coins}}
                              </v-col>
                              <v-col cols=12 class="text-center">
                                  <img :src="getCoinsImg(r)" />
                              </v-col>
                                <v-col cols=12 class="text-center">
                                  {{days[r.day]}}
                              </v-col>
                            </v-row>
                          </v-col>
                          <!-- <v-col>
                            <v-divider v-if="index !== dailyRewards.length - 1" class="coin-divider"></v-divider>
                          </v-col> -->
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="mb-5 d-flex justify-center align-center" :class="$vuetify.breakpoint.mdAndDown ? 'px-2' :'px-5'">
                    <app-button class="dialog-button white--text" :action="this.checkInDay" :title="$t(`button.checkInToday`)"></app-button>
                  </v-col>
              </v-row>
            </v-row>
        </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="directToWOFDialogShow" transition="top">
      <v-card color="white" min-height="200" max-width="500px">
        <v-card-title class="primary white--text">
          {{ $t(`label.dailyCheckIn`) }}
        </v-card-title>
        <div class="pa-5">
          <div>
            <span>{{ $t(`label.trigger_redirect_wof_msg`) }}</span>
          </div>
        </div>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="navigateWOFPage()" ripple outlined class="primary white--text" style="min-width: 100%;">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  DevicePlatform
} from '@/constants/enums'
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import {
  uiHelper,
  locale,
  formValidator,
  errorCodeHelper
} from '@/util'
import {MEMBER_DETAIL} from "@/store/member.module";
import {WHEEL_MODULE} from "@/store/wheel.module";
import {
  REWARD_GET_WALLET_LOG,
  REWARD_GET_CHECK_IN_DAY,
  REWARD_POST_CHECK_IN_DAY,
  REWARD_ACCEPT_CHALLENGE,
  REWARD_GET_MEMBER_CHALLENGE,
  REWARD_GET_DAILY_CHECK_IN_PRIZE_LIST,
  REWARD_RESET_POST_CHECK_IN_DAY,
  REWARD_CONFIGURATION_STATUS
} from '@/store/reward.module'
import {GRAB_COIN_SETTING} from "@/store/bonus.module";

export default {
  name: 'rewards',
  data: () => ({
    directToWOFDialogShow: false,
    dailyRewardsDialogShow: false,
    dateMenu: false,
    dates: ['', ''],
    dayOfWeekNumber: new Date().getDay(),
    devicePlatform: DevicePlatform,
    validator: formValidator,
    currentCurrency: uiHelper.getCurrency(),
    transactionList: [],
    dailyRewards: [],
    headers: [{
      text: 'transactionDate',
      align: 'start',
      sortable: true,
      value: 'member_id',
    },
      {
        text: 'transactionId',
        value: 'membercode',
        sortable: false,
      },
      {
        text: 'balanceBefore',
        value: 'commission',
        sortable: false,
      },
      {
        text: 'balanceAfter',
        value: 'status',
        sortable: false,
      },
      {
        text: 'amount',
        value: 'status',
        sortable: false,
      },
      {
        text: 'activity',
        value: 'status',
        sortable: false,
      }
    ],
    routeName: ROUTE_NAME,
    days: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
    pagination: {},
    selectedPage: 1
  }),
  created() {
    this.metaTag()
    this.getDailyCheckInPrizeList()
    this.getGrabCoinSetting()
    this.getRewardWalletLog()
    this.getRewardCheckInDay()
    this.getChallengeByMember()
    this.getMemberDetail()
    this.getWOF()
    this.getGrabCoinSetting()
    this.getConfigurationStatus()
  },
  computed: {
    dateRangeText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        let endDate = this.dates[1] ? this.dates[1] : ''
        return startDate + ' - ' + endDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    rewardWalletLog() {
      return this.$store.state.reward.rewardWalletLog.data
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberChallenge() {
      return this.$store.state.reward.memberChallenge
    },
    rewardCheckInDay() {
      return this.$store.state.reward.checkInDay
    },
    checkInDayResponse() {
      return this.$store.state.reward.checkInDayResponse.complete
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    dailyCheckInPrizeList() {
      return this.$store.state.reward.dailyCheckInPrizeList
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      return d
    },
    configuration() {
      let d = this.$store.state.reward.configurationStatus
      if (d.daily_check_in.enabled) {
        this.dailyRewardsDialogShow = true;
      }
      return d
    }
  },
  watch: {
    rewardWalletLog() {
      this.transactionList = this.$store.state.reward.rewardWalletLog.data
      this.pagination = this.$store.state.reward.rewardWalletLog.pagination
    },
    dailyCheckInPrizeList() {
      this.dailyRewards = this.$store.state.reward.dailyCheckInPrizeList[0].daily_coins_given
    },
    checkInDayResponse() {
      if (this.$store.state.reward.checkInDayResponse.complete) {
        this.checkInComplete(this.$store.state.reward.checkInDayResponse)
      }
    }
  },
  methods: {
    navigateToCardManiaPage() {
      this.$router.push({
        name: ROUTE_NAME.CARD_MANIA,
        params: {
          lang: this.$route.params.lang
        }
      });
    },
    metaTag() {
      const currentUrl = window.location.href;
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    getConfigurationStatus() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${REWARD_CONFIGURATION_STATUS}`, {filter})
    },
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        let self = this
        setTimeout(function () {
          self.dateMenu = false
        }, 500);
      }
    },
    checkInComplete(res) {
      if (res.success) {
        this.$parent.openAlert(true, locale.getMessage(`message.checkedIn`))
        this.getMemberDetail()
        this.getRewardCheckInDay()
        this.getRewardWalletLog()
        this.closeDailyRewardsDialog()
        const coin = Number(this.memberInfo.rewardBalance) + Number(this.dailyRewards[this.dayOfWeekNumber - 1].coins);
        if (coin >= 10) {
          this.directToWOFDialogShow = true
        }
      } else {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(res.code))
        this.getRewardCheckInDay()
        this.getRewardWalletLog()
        this.closeDailyRewardsDialog()
      }
      this.$store.dispatch(`${REWARD_RESET_POST_CHECK_IN_DAY}`)
    },
    getCoinsImg(reward) {
      let img = "/static/svg/coin_checked.svg"
      let img2 = "/static/svg/coin_unchecked.svg"
      let img3 = "/static/svg/coin.svg"
      if (this.rewardCheckInDay.includes(reward.day)) {
        return "/static/svg/coin_checked.svg"
      } else {
        if (reward.day < this.dayOfWeekNumber && reward.day != 0) {
          return "/static/svg/coin_unchecked.svg"
        } else {
          return "/static/svg/coin.svg"
        }
      }
    },
    getMemberDetail() {
      if (this.isLoggedIn)
        this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
      this.getRewardWalletLog()
    },
    filterListByDate() {
      this.getRewardWalletLog()
    },
    getRewardWalletLog() {
      let obj = {
        size: 20,
        page: this.selectedPage,
        startTime: this.dates[0],
        endTime: this.dates[1]
      }
      this.$store.dispatch(`${REWARD_GET_WALLET_LOG}`, {obj})
    },
    getChallengeByMember() {
      if (this.isLoggedIn)
        this.$store.dispatch(`${REWARD_GET_MEMBER_CHALLENGE}`)
    },
    getDailyCheckInPrizeList() {
      let obj = {
        id: 2
      }
      this.$store.dispatch(`${REWARD_GET_DAILY_CHECK_IN_PRIZE_LIST}`, {obj})
    },
    getRewardCheckInDay() {
      this.$store.dispatch(`${REWARD_GET_CHECK_IN_DAY}`)
    },
    acceptChallenge() {
      let obj = {
        id: 2
      }
      this.$store.dispatch(`${REWARD_ACCEPT_CHALLENGE}`, {obj})
    },
    checkInDay() {
      let obj = {
        checkinDay: this.dayOfWeekNumber
      }
      this.$store.dispatch(`${REWARD_POST_CHECK_IN_DAY}`, {obj})
    },
    changePasswordResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.memberPasswordUpdated`))
      }
    },
    clearFilter() {
      this.dates = []
      this.getRewardWalletLog()
      //this.onChangeTab(this.selectedTab)
    },
    closeDailyRewardsDialog() {
      this.dailyRewardsDialogShow = false
    },
    openDailyRewardsDialog() {
      if (this.memberChallenge.fk_challenge_id != 2) {
        this.acceptChallenge()
      }
      this.dailyRewardsDialogShow = true
    },
    navigateWOFPage() {
      if (uiHelper.getPlatform() == DevicePlatform.DESKTOP) {
        this.$router.push({
          name: ROUTE_NAME.WOF,
          params: { lang: this.$route.params.lang }
        })
      } else {
        this.$router.push({
          name: ROUTE_NAME.MWOF,
          params: { lang: this.$route.params.lang }
        })
      }
    },
    getWOF() {
      let currency = uiHelper.getCurrency();
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, {filter})
    },
    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, {filter})
    },
    navigateToCoinPage() {
      this.$router.push({
        name: ROUTE_NAME.GRAB_COIN,
        params: {
          type: 'coin',
          lang: this.$route.params.lang
        }
      })
    },
  }
}
</script>

<style lang="scss">
.rewards-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.reward_log_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.reward-table-header {
  color: #ACACAC !important;
}

.rewards-date-search {
  width: fit-content;
  margin-right: 0px;
  float: right !important;

  .v-input__slot {
    min-height: 40px !important;
    max-width: 250px;
  }

  .v-input__append-inner {
    margin-top: 8px;
  }
}

.reward-icon-label {
  color: #3a3a3a;
  font-size: 12px;
}

.coin-divider {
  margin-top: 33px;
}

.coin-divider.v-divider {
  border-color: #ACACAC;
  border-width: 1px !important;
}

.daily-coins-progress {
  font-size: 12px !important;
}

.daily-rewards-card {
  .dialog-title {
    font-size: 20px;
  }

  .dialog-subtitle {
    color: #ACACAC;
    font-size: 14px;
  }

  .dialog-header {
    position: relative;
  }

  .dialog-close-icon {
    position: absolute;
    top: 70% !important;
    font-size: 30px !important;
    cursor: pointer;
    transform: translateY(-50%);

    &.register,
    &.login {
      top: 23%;
    }
  }

  .dialog-row {
    overflow-y: scroll;
  }

  .dialog-divider {
    border-width: 0.8px !important;
    border-color: #ACACAC !important;
    height: 100%;
  }
}

.daily-rewards-dialog.v-dialog {
  margin: 24px;
}

.daily-rewards-dialog.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

@media (max-width: 599px) {
  .rewards-card {
    height: fit-content;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .mobile-reward-table {
    font-size: 12px;
  }
  .rewards-date-search {
    width: 100%;
    margin-right: 0px;
    float: right !important;

    .v-input__slot {
      min-height: 40px !important;
      max-width: 100%;
    }

    .v-input__append-inner {
      margin-top: 8px;
    }
  }
  .daily-rewards-dialog.v-dialog {
    margin: 16px;
  }
  .daily-rewards-card {
    .dialog-title {
      font-size: 16px !important;
    }

    .dialog-subtitle {
      color: #ACACAC;
      font-size: 12px;
    }
  }

}
</style>
