<template>
  <div class="profile-container">
    <div class="profile-wrapper">
      <!-- <v-card-title>
          <label class="--v-primary-base text-left text-capitalize mr-4">{{ $t(`label.myProfile`) }}</label>
          <v-btn class="icon-btn" icon v-if="!isPersonalProfileComplete" @click="openEditDialog()">
            <account-edit></account-edit>
          </v-btn>
        </v-card-title> -->
      <div class="avatar-wrapper">
        <div class="avatar" @click="openEditDialog()"></div>
      </div>
      <div class="member-name mt-2">{{ memberInfo.name }}</div>
      <div class="profile-detail">
        <v-row no-gutters justify="end">
          <div class="editBtn">
            <div class="px-2 warning-text" @click="openEditDialog()">{{ $t(`label.editProfile`) }}</div>
          </div>
        </v-row>
        <v-col class="no-gutters">
          <div class="field-name">{{ $t(`field.username`) }}</div>
          <div class="orange--text">{{ memberInfo.memberCode }}</div>
        </v-col>
        <v-col class="no-gutters">
          <div class="field-name">{{ $t(`field.phoneNumber`) }}</div>
          <v-row no-gutters class="mobile-input" justify="space-between pa-0" v-for="(n, j) in memberInfo.mobileList" :index="j">
            <v-col cols="12" v-if="j == 0" class="d-flex align-center justify-space-between remove-bord-btm">
              <div class="orange--text">{{ mobilePrefix }} {{ n.value | maskInfo }}</div>
              <v-spacer></v-spacer>
              <div v-if="n.is_verified">
                <p class="px-2 verified-text d-flex">
                  <v-img src="/static/image/profile/personal-info-verified.svg" width="25" height="25" class="mr-2" :contain="true"></v-img>
                  Verified
                </p>
              </div>
              <div @click="openOTPDialog(j)" v-else>
                <label class="px-2 warning-text d-flex">
                  Verify OTP
                  <v-img src="/static/image/profile/personal-info-warning.svg" width="25" height="25" class="ml-2" :contain="true"></v-img>
                </label>
              </div>
            </v-col>
            <v-col v-if="j > 0" class="d-flex align-center justify-space-between remove-bord-btm">
              <div class="primary--text font-weight-bold" style="font-size:12px;">
                {{ j < 2 ? $t(`label.secondaryNumber`) : $t(`label.tertiaryNumber`) }}
              </div>
              <v-spacer></v-spacer>
              <div @click="!n.is_verified ? openOTPDialog(j) : ''">
                <p :class="['px-2 verified-text d-flex  mb-1', !n.is_verified ? 'text-decoration-underline secondary--text' : 'primary--text']" style="font-size: 12px;">
                  {{ mobilePrefix }}
                  {{ n.value | maskInfo }}
                  <!--                  <v-img src="/static/image/profile/personal-info-verified.svg" width="15" height="15" class="ml-2"-->
                  <!--                         :contain="true"></v-img>-->
                  <v-icon small color="primary" v-if="n.is_verified">mdi-check-circle-outline</v-icon>
                  <v-icon small color="secondary" v-else>mdi-information-outline</v-icon>
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="memberInfo.mobileList.length < 3">
            <v-col cols="12" class="pt-0">
              <v-btn rounded color="primary" small @click="verifyPrimaryNumber()">
                {{ memberInfo.mobileList.length == 1 ? 'Add Secondary Number' : 'Add Tertiary Number' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="no-gutters">
          <div class="field-name">{{ $t(`field.email`) }}</div>
          <v-row no-gutters justify="space-between">
            <label class="orange--text">{{ memberInfo.email.value }}</label>
            <!-- <v-col class="orange--text"></v-col> -->
            <div @click="openEditDialog()" class="d-flex align-center"></div>
          </v-row>
        </v-col>
        <v-col class="no-gutters">
          <div class="field-name">{{ $t(`field.dateOfBirth`) }}</div>
          <v-row no-gutters justify="space-between">
            <div class="orange--text">{{ memberInfo.dateOfBirth | shortDate }}</div>
          </v-row>
        </v-col>

        <v-col class="no-gutters">
          <div class="field-name">{{ $t(`field.currency`) }}</div>
          <div>{{ memberInfo.currency }}</div>
        </v-col>
      </div>
    </div>
    <app-dialog
      :dialogShow="editDialogShow"
      ref="personalEditDialog"
      :max-width="400"
      :title="$t(`label.editProfile`)"
      :isAuth="false"
      :header_title="'editProfile'"
      :closeAction="this.closeEditDialog"
    >
      <app-personal-edit :personalInfo="personalInfo" :closeAction="this.closeEditDialog"></app-personal-edit>
    </app-dialog>

    <app-dialog :title="$t(`label.addNewNumber`)" :dialog-show="addNewNumberDialog" ref="addNewNumberOtpDialog" :closeAction="this.closeAddNumberDialog" :max-width="400">
      <v-form ref="addNumberForm">
        <template>
          <app-form-field
            :enabledLabel="true"
            v-model.trim="newContactNumber.mobile"
            :label="$t(`field.phoneNumber`)"
            :readonly="!newContactNumber.is_new_number"
            class="otpPhoneNo pb-2"
            :rules="validator.mobileNumberRules(defaultLanguage)"
            :prefix="mobilePrefix"
          ></app-form-field>
          <v-row no-gutters class="d-flex justify-center align-center">
            <app-button class="otpSubmitButton white--text" :action="this.AddNewSubContact" :title="$t(`button.submit`)"></app-button>
          </v-row>
        </template>
      </v-form>
    </app-dialog>
    <app-dialog :dialogShow="otpDialogShow" ref="personalOTPDialog" :closeAction="this.closeOTPDialog" :max-width="400" :title="$t(`label.verifyPhoneNo`)">
      <v-form ref="otpForm">
        <template>
          <captcha ref="captchaCode" v-model.trim="captcha" :isOptional="true" :errorMsg="captchaErrorMsg" :rules="validator.captchaRules()"></captcha>
          <app-form-field
            :enabledLabel="true"
            v-model.trim="this.memberInfo.mobileList[0].value"
            :label="$t(`field.firstNumber`)"
            :readonly="!newContactNumber.is_new_number"
            class="otpPhoneNo pb-2"
            :rules="validator.mobileNumberRules(defaultLanguage)"
            :prefix="mobilePrefix"
          ></app-form-field>
          <v-row no-gutters class="pb-5">
            <v-col cols="5">
              <div class="mr-2">
                <v-btn
                  height="auto"
                  :disabled="this.$store.state.member.smsVerificationSent.countDownTimer > 0"
                  @click="this.requestSmsVerificationCode"
                  color="secondary"
                  depressed
                  class="otp-button theme-button font-weight-bold white--text mr-2"
                >
                  {{ this.$store.state.member.smsVerificationSent.countDownTimer > 0 ? formatSeconds(this.$store.state.member.smsVerificationSent.countDownTimer) : $t(`button.requestOtp`) }}
                </v-btn>
              </div>
            </v-col>
            <v-col>
              <app-form-field :enabledLabel="false" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules()" v-model.trim="verificationCode" type="text"></app-form-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="d-flex justify-center align-center">
            <app-button
              class="otpSubmitButton white--text"
              :disabled="!verificationCode"
              :action="newContactNumber.is_new_number ? this.verifyAndAddNewContact : this.verifySmsCode"
              :title="$t(`button.submit`)"
            ></app-button>
          </v-row>
        </template>
      </v-form>
    </app-dialog>

    <app-dialog :dialogShow="otpResultDialogShow" ref="otpResultDialog" :closeAction="this.closeOtpResultDialog" :max-width="350" :title="$t(`label.OTPResult`)">
      <label>{{ smsVerificationResult ? 'Success' : 'Failed, try again' }}</label>
    </app-dialog>
  </div>
</template>
<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import {
  MEMBER_DETAIL,
  MEMBER_UPDATE_DETAIL,
  MEMBER_RESET_UPDATE_DETAIL_STORE,
  MEMBER_SMS_REQUEST,
  MEMBER_SMS_VERIFY,
  MEMBER_REST_OTPRESULT,
  MEMBER_PHONE_VERIFICATION_STATUS,
  MEMBER_2ND_PHONE_VERIFICATION_STATUS,
  MEMBER_RESET_SMS_VERIFICATION,
  MEMBER_ADD_NEW_CONTACT,
  MEMBER_RESET_ADD_NEW_CONTACT
} from '@/store/member.module'
import { LOOKUP_COUNTRY, LOOKUP_PROVINCE } from '@/store/lookup.module'
import AccountEdit from '@/assets/svg/profile/edit.svg'
import AppPersonalEdit from '@/components/member/personalInfoEdit.vue'
import find from 'lodash/find'
import Captcha from '@/components/Captcha.vue'

export default {
  name: 'myProfile',
  components: {
    Captcha,
    AccountEdit,
    AppPersonalEdit
  },
  data: () => ({
    is_add_new_number: false,
    addNewNumberDialog: false,
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    newContactNumber: {
      mobile: '',
      is_new_number: false
    },
    phonePriority: 0,
    verificationCode: '',
    language: uiHelper.getLanguage(),
    validator: formValidator,
    dateOfBirthMaxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().substr(0, 10),
    dateOfBirthMenu: false,
    shared: SHARED,
    isPersonalProfileComplete: false,
    tempMemberInfo: {
      email: '',
      name: ''
    },
    profileTab: [
      {
        key: 'account',
        link: ROUTE_NAME.PROFILE
      },
      {
        key: 'personal',
        link: ROUTE_NAME.PERSONAL
      }
    ],
    personalInfoObj: {
      gender: '',
      dateOfBirth: '',
      country: '',
      province: '',
      postcode: '',
      nationality: '',
      address: ''
    },
    editDialogShow: false,
    otpDialogShow: false,
    otpResultDialogShow: false,
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: 'INR'
      },
      {
        text: '+880',
        value: 'BDT'
      }
    ],
    personalInfo: {
      memberCode: '',
      dateOfBirth: '',
      mobile: {
        value: ''
      },
      email: null,
      name: '',
      currency: ''
    },
    captcha: '',
    captchaErrorMsg: undefined
  }),
  created() {
    this.metaTag()
    this.getMemberDetail()
    this.getCountry()
  },
  computed: {
    smsOTPErrorMsg() {
      return this.$store.state.member.smsVerificationSent.code
    },
    smsCountdownTimer() {
      return this.$store.state.member.smsVerificationSent.countDownTimer.toString()
    },
    smsVerificationSent() {
      return this.$store.state.member.smsVerificationSent.success
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.loaded
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    updateDetailResponseComplete() {
      return this.$store.state.member.updateDetailResponse.complete
    },
    addNewContactNumberResponse() {
      return this.$store.state.member.addNewNumberContact.complete
    },
    country() {
      return this.$store.state.lookup.country
    },
    province() {
      return this.$store.state.lookup.province
    },
    mobilePrefix() {
      let self = this
      let prefix = find(this.mobileNumberPrefixList, function(p) {
        return p.value == self.memberInfo.currency
      })
      if (prefix) return prefix.text
      else return ''
    },
    cPhonePriority() {
      let arrIndex = this.phonePriority
      const mobileList = this.memberInfo.mobileList
      if (Array.isArray(mobileList) && mobileList.length > arrIndex) {
        this.newContactNumber.mobile = mobileList[arrIndex].value
        return mobileList[arrIndex].value
      }
    }
  },
  watch: {
    smsOTPErrorMsg() {
      let errorCode = this.$store.state.member.smsVerificationSent.code
      if (errorCode == -1) {
        this.$parent.openAlert(false, locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
      } else if (errorCode == -3) {
        this.$parent.openAlert(false, locale.getMessage('fieldErrorMessage.tooManyRequest'))
      } else {
        this.errorDialog(errorCode)
      }
    },
    smsVerificationResult() {
      let response = this.$store.state.member.smsVerificationResult
      //show alert on repsonse //loaded =true : back from server with response
      if (response.loaded) {
        if (response.success) {
          //if reponse=phone verified show alert and close while otp window
          this.$parent.openAlert(true, 'Phone verified successfully')
          this.otpDialogShow = false

          if (this.is_add_new_number) {
            this.newContactNumber.is_new_number = true
            this.newContactNumber.mobile = ''
            this.addNewNumberDialog = true
            this.is_add_new_number = false
          }
          let index = this.phonePriority
          this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`, index)

          // if (this.phonePriority == 1) {
          //   this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`)
          // } else if (this.phonePriority == 2) {
          //   this.$store.dispatch(`${MEMBER_2ND_PHONE_VERIFICATION_STATUS}`)
          // }
        } else {
          if (response.code == -1) {
            this.$parent.openAlert(response.success, locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
          } else if (response.code == -3) {
            this.$parent.openAlert(response.success, locale.getMessage('fieldErrorMessage.tooManyRequest'))
          } else {
            this.$parent.openAlert(true, `Failed to verify (${response.message}). please try again`)
          }
        }
        this.$store.dispatch(`${MEMBER_REST_OTPRESULT}`)
      }
    },
    memberInfo() {
      let info = this.$store.state.member.info
      let primaryNumber = info.mobileList[0].value
      this.personalInfo = {
        memberCode: info.memberCode,
        dateOfBirth: info.dateOfBirth,
        mobile: {
          value: primaryNumber
        },
        name: info.name,
        currency: info.currency,
        email: info.email
      }

      if (info.dateOfBirth && info.name && info.email.value) {
        this.isPersonalProfileComplete = true
      }
      return info
    },
    dateOfBirthMenu(val) {
      val && setTimeout(() => (this.$refs.dateOfBirthPicker.activePicker = 'YEAR'))
    },
    updateDetailResponseComplete() {
      let response = this.$store.state.member.updateDetailResponse
      if (response.complete) {
        this.updateDetailResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_DETAIL_STORE}`)
      }
    },
    addNewContactNumberResponse() {
      if (this.$store.state.member.addNewNumberContact.complete) {
        if (this.$store.state.member.addNewNumberContact.success) {
          this.$parent.openAlert(true, 'Phone verified successfully')
          this.otpDialogShow = false
          this.addNewNumberDialog = false
          let index = this.phonePriority
          this.getMemberDetail()
          this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`, index)
        } else {
          this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(this.$store.state.member.addNewNumberContact.code))
        }
        this.$store.dispatch(`${MEMBER_RESET_ADD_NEW_CONTACT}`)
        this.$store.dispatch(`${MEMBER_REST_OTPRESULT}`)
        this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      }
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    verifyPrimaryNumber() {
      this.phonePriority = 0
      this.newContactNumber.is_new_number = false
      this.newContactNumber.mobile = this.memberInfo.mobileList[0].value
      this.otpDialogShow = true
      this.is_add_new_number = true
    },
    openAddNewNumberDialog(phoneNumCount) {
      this.phonePriority = phoneNumCount
      this.newContactNumber.is_new_number = true
      this.newContactNumber.mobile = ''
      this.otpDialogShow = true
    },
    errorDialog(errorCode) {
      if (errorCode == 0) {
        return
      }
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.system`)
      dialog.dialogXButton = null
      let msg = errorCodeHelper.getErrorCodeDesc(errorCode)
      if (errorCode == '3.65') {
        let k = this.$store.state.member.smsVerificationSent.message
        if (k != null) {
          let arr = k.replaceAll(' ', '').split('|')
          if (typeof arr != 'undefined' && arr != null) {
            if (arr.length === 2) {
              let minutes = arr[1]
              msg = errorCodeHelper.getErrorCodeDesc(errorCode, minutes)
            }
          }
        }
      }
      if (errorCode == '3.32.6') {
        msg += '. ' + locale.getMessage('label.contactCsChangePhoneNo')
      }
      dialog.message.push(msg)
      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      this.$eventHub.$emit('close-page-dialog', true)
    },
    closeOtpResultDialog() {
      this.otpResultDialogShow = false
    },
    formatSeconds(secs) {
      const pad = n => (n < 10 ? `0${n}` : n)

      const h = Math.floor(secs / 3600)
      const m = Math.floor(secs / 60) - h * 60
      const s = Math.floor(secs - h * 3600 - m * 60)

      return `${pad(m)}:${pad(s)}`
    },
    async AddNewSubContact() {
      if (this.$refs.addNumberForm.validate()) {
        let filter = {
          verification_code: '',
          mobileNumber: this.newContactNumber.mobile,
          required_only_primary_verified: true
        }

        this.$store.dispatch(`${MEMBER_ADD_NEW_CONTACT}`, {
          filter
        })
      }
    },
    async verifyAndAddNewContact() {
      if (this.$refs.otpForm.validate()) {
        let filter = {
          verification_code: this.verificationCode,
          mobileNumber: this.newContactNumber.mobile,
          required_only_primary_verified: true
        }

        this.$store.dispatch(`${MEMBER_ADD_NEW_CONTACT}`, {
          filter
        })
      }
    },
    async verifySmsCode() {
      let verifyPhoneNumber = this.memberInfo.mobileList[0].value
      {
        let registerObj = {
          verificationCode: this.verificationCode,
          mobileNumber: verifyPhoneNumber,
          captchaId: this.$refs.captchaCode.$refs.code.id,
          captchaCode: this.captcha
        }
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      let verifyPhoneNumber = this.memberInfo.mobileList[0].value
      //if (this.$refs.registerForm2.validate()) {
      if (this.captcha != '') {
        let registerObj = {}
        registerObj.domain = uiHelper.getHostname()
        registerObj.language = uiHelper.getLanguage()
        registerObj.currency = this.personalInfo.currency
        registerObj.memberCode = this.personalInfo.memberCode
        registerObj.mobileNumber = verifyPhoneNumber
        registerObj.request_otp = true
        registerObj.captchaId = this.$refs.captchaCode.$refs.code.id
        registerObj.captchaCode = this.captcha

        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
      //}
    },
    openEditDialog() {
      console.log('open', this.memberInfo)
      this.editDialogShow = true
    },
    closeEditDialog() {
      this.editDialogShow = false
    },
    openOTPDialog(_phonePriority) {
      this.phonePriority = _phonePriority
      let arrIndex = this.phonePriority
      const mobileList = this.memberInfo.mobileList
      if (Array.isArray(mobileList) && mobileList.length > arrIndex) {
        this.newContactNumber.mobile = mobileList[arrIndex].value
      }
      this.otpDialogShow = true
    },
    closeAddNumberDialog() {
      this.addNewNumberDialog = false
    },
    closeOTPDialog() {
      this.otpDialogShow = false
    },
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    getCountry() {
      let countryLookupObj = {
        language: uiHelper.getCookie('language') + '-cn'
      }
      this.$store.dispatch(`${LOOKUP_COUNTRY}`, { countryLookupObj })
    },
    getCountryProvince(data) {
      let provinceLookupObj = {
        language: uiHelper.getCookie('language') + '-cn',
        countryCode: data
      }
      this.$store.dispatch(`${LOOKUP_PROVINCE}`, { provinceLookupObj })
    },
    countryDisplayText(data) {
      return data.displayText
    },
    provinceDisplayText(data) {
      return data.displayText
    },
    updatePersonalInfo() {
      if (this.$refs.profileForm.validate()) {
        let memberObj = this.personalInfoObj

        let countryLocalname = this.country.find(x => x.value == memberObj.country).displayText
        let nationalityLocalname = this.country.find(x => x.value == memberObj.nationality).displayText
        memberObj.country = countryLocalname
        memberObj.nationality = nationalityLocalname
        this.$store.dispatch(`${MEMBER_UPDATE_DETAIL}`, { memberObj })
      }
    },
    updateDetailResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.memberPersonalDetailUpdated`))
      }
    },
    openLiveChat() {
      this.$parent.$parent.openLiveChat()
    }
  }
}
</script>
<style lang="scss">
.profile-wrapper .col {
  border-bottom: ridge;
}

.profile-wrapper .col:hover {
  opacity: 0.9;
}

.otpSubmitButton {
  width: 100% !important;
}

.otpPhoneNo {
  width: 100% !important;
}

.otp-button {
  background-color: var(--v-primary-base);
  border-radius: 4px !important;
  min-height: 43px;
}

.profile-container {
  .profile-wrapper {
    max-width: 1240px;
    margin: auto;

    .avatar-wrapper {
      position: relative;
      margin: 12px;
      background: url('/static/image/profile/personal-info.jpg');
      background-repeat: no-repeat;
      max-width: 708px;
      height: 124px;
      border-radius: 15px;
      margin: auto;

      .avatar {
        position: absolute;
        bottom: -42px;
        left: calc(50% - 42px);
        z-index: 1;
        background: url('/static/svg/memberpic.svg');
        height: 84px;
        width: 84px;
        cursor: pointer;
      }
    }

    .member-name {
      position: relative;
      top: 42px;
      display: flex;
      justify-content: center;
    }

    .profile-detail {
      position: relative;
      margin: 54px auto 0px auto;
      max-width: 708px;
      background-color: #eafaf1;

      .mobile-input {
        position: relative;
        padding: 8px;
        display: flex;
        justify-content: space-between;
      }

      .warning-text {
        color: var(--v-warning-base);
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
      }

      .verified-text {
        color: var(--v-warning-base);
        font-size: 14px;
      }

      .editBtn {
        padding-top: 8px;
        padding-right: 8px;
      }
    }
  }
}

.profile-live-chat {
  color: #f88717 !important;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 959px) {
  .profile-page {
    hr {
      display: none;
    }

    .v-card {
      box-shadow: none;
    }
  }
  .page-title-container {
    display: none;
  }
}

.remove-bord-btm {
  border-bottom: none !important;
}
</style>
